"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_SENSITATION = void 0;
var client_1 = require("@apollo/client");
var GET_SENSITATION = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tquery getSensitationByOrganization(\n\t\t$organization_id: ID!\n\t\t$published: Boolean\n\t) {\n\t\tgetSensitationByOrganization(\n\t\t\torganization_id: $organization_id\n\t\t\tpublished: $published\n\t\t) {\n\t\t\tid\n\t\t\ttitle\n\t\t\tdescription\n\t\t\tsorting\n\t\t\tpublished\n\t\t\torganization_id\n\t\t\tcreated_at\n\t\t\tsensitation_media {\n\t\t\t\tid\n\t\t\t\tvideo_link\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery getSensitationByOrganization(\n\t\t$organization_id: ID!\n\t\t$published: Boolean\n\t) {\n\t\tgetSensitationByOrganization(\n\t\t\torganization_id: $organization_id\n\t\t\tpublished: $published\n\t\t) {\n\t\t\tid\n\t\t\ttitle\n\t\t\tdescription\n\t\t\tsorting\n\t\t\tpublished\n\t\t\torganization_id\n\t\t\tcreated_at\n\t\t\tsensitation_media {\n\t\t\t\tid\n\t\t\t\tvideo_link\n\t\t\t}\n\t\t}\n\t}\n"])));
exports.GET_SENSITATION = GET_SENSITATION;
var templateObject_1;
