"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_LANGUAGE_WITH_TRANSLATION = exports.GET_LANGUAGES = exports.GET_BACKEND_ROLES = void 0;
var client_1 = require("@apollo/client");
// const GET_LANGUAGE_WITH_TRANSLATION = gql`
// 	query getLanguageWithTranslation($country_code: String) {
// 		getLanguageWithTranslation(country_code: $country_code) {
// 			label
// 			code
// 			translation
// 		}
// 	}
// `;
var GET_LANGUAGES = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tquery getLanguage($country_code: String) {\n\t\tgetLanguage(country_code: $country_code) {\n\t\t\tid\n\t\t\tcode\n\t\t\tname\n\t\t}\n\t}\n"], ["\n\tquery getLanguage($country_code: String) {\n\t\tgetLanguage(country_code: $country_code) {\n\t\t\tid\n\t\t\tcode\n\t\t\tname\n\t\t}\n\t}\n"])));
exports.GET_LANGUAGES = GET_LANGUAGES;
var GET_LANGUAGE_WITH_TRANSLATION = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tquery getLanguageWithTranslation(\n\t\t$country_code: String!\n\t\t$language_code: String\n\t) {\n\t\tgetLanguageWithTranslation(\n\t\t\tcountry_code: $country_code\n\t\t\tlanguage_code: $language_code\n\t\t) {\n\t\t\tlabel\n\t\t\tcode\n\t\t\ttranslation\n\t\t}\n\t}\n"], ["\n\tquery getLanguageWithTranslation(\n\t\t$country_code: String!\n\t\t$language_code: String\n\t) {\n\t\tgetLanguageWithTranslation(\n\t\t\tcountry_code: $country_code\n\t\t\tlanguage_code: $language_code\n\t\t) {\n\t\t\tlabel\n\t\t\tcode\n\t\t\ttranslation\n\t\t}\n\t}\n"])));
exports.GET_LANGUAGE_WITH_TRANSLATION = GET_LANGUAGE_WITH_TRANSLATION;
// const GET_LANGUAGES = gql`
// 	query getLanguage($country_code: String) {
// 		getLanguage(country_code: $country_code) {
// 			id
// 			code
// 			name
// 		}
// 	}
// `;
var GET_BACKEND_ROLES = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tquery getBackendRoles {\n\t\tgetBackendRoles {\n\t\t\trole_id\n\t\t\trole_name\n\t\t}\n\t}\n"], ["\n\tquery getBackendRoles {\n\t\tgetBackendRoles {\n\t\t\trole_id\n\t\t\trole_name\n\t\t}\n\t}\n"])));
exports.GET_BACKEND_ROLES = GET_BACKEND_ROLES;
var templateObject_1, templateObject_2, templateObject_3;
