"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_KNOWLEDGE_CATEGORY = exports.GET_KNOWLEDGE = void 0;
var client_1 = require("@apollo/client");
var GET_KNOWLEDGE_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tquery getKnowledgeCategoryByOrganization($organization_id: ID!) {\n\t\tgetKnowledgeCategoryByOrganization(organization_id: $organization_id) {\n\t\t\tid\n\t\t\tname\n\t\t\ticon\n\t\t\ticon_web\n\t\t\tsorting\n\t\t\torganization_id\n\t\t\tcreated_at\n\t\t\tknowledges {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\tdescription\n\t\t\t\tsorting\n\t\t\t\tpublished\n\t\t\t\torganization_id\n\t\t\t\tknowledge_category_id\n\t\t\t\tcreated_at\n\t\t\t\tknowledge_media {\n\t\t\t\t\tid\n\t\t\t\t\tvideo_link\n\t\t\t\t\tweb_video_link\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery getKnowledgeCategoryByOrganization($organization_id: ID!) {\n\t\tgetKnowledgeCategoryByOrganization(organization_id: $organization_id) {\n\t\t\tid\n\t\t\tname\n\t\t\ticon\n\t\t\ticon_web\n\t\t\tsorting\n\t\t\torganization_id\n\t\t\tcreated_at\n\t\t\tknowledges {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\tdescription\n\t\t\t\tsorting\n\t\t\t\tpublished\n\t\t\t\torganization_id\n\t\t\t\tknowledge_category_id\n\t\t\t\tcreated_at\n\t\t\t\tknowledge_media {\n\t\t\t\t\tid\n\t\t\t\t\tvideo_link\n\t\t\t\t\tweb_video_link\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"])));
exports.GET_KNOWLEDGE_CATEGORY = GET_KNOWLEDGE_CATEGORY;
var GET_KNOWLEDGE = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tquery getKnowledgeByOrganization(\n\t\t$knowledge_category_id: ID!\n\t\t$organization_id: ID!\n\t\t$published: Boolean\n\t) {\n\t\tgetKnowledgeByOrganization(\n\t\t\tknowledge_category_id: $knowledge_category_id\n\t\t\torganization_id: $organization_id\n\t\t\tpublished: $published\n\t\t) {\n\t\t\tid\n\t\t\ttitle\n\t\t\tdescription\n\t\t\tsorting\n\t\t\tpublished\n\t\t\torganization_id\n\t\t\tknowledge_category_id\n\t\t\tcreated_at\n\t\t\tknowledge_media {\n\t\t\t\tid\n\t\t\t\tvideo_link\n\t\t\t\tweb_video_link\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery getKnowledgeByOrganization(\n\t\t$knowledge_category_id: ID!\n\t\t$organization_id: ID!\n\t\t$published: Boolean\n\t) {\n\t\tgetKnowledgeByOrganization(\n\t\t\tknowledge_category_id: $knowledge_category_id\n\t\t\torganization_id: $organization_id\n\t\t\tpublished: $published\n\t\t) {\n\t\t\tid\n\t\t\ttitle\n\t\t\tdescription\n\t\t\tsorting\n\t\t\tpublished\n\t\t\torganization_id\n\t\t\tknowledge_category_id\n\t\t\tcreated_at\n\t\t\tknowledge_media {\n\t\t\t\tid\n\t\t\t\tvideo_link\n\t\t\t\tweb_video_link\n\t\t\t}\n\t\t}\n\t}\n"])));
exports.GET_KNOWLEDGE = GET_KNOWLEDGE;
var templateObject_1, templateObject_2;
