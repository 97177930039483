"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPSERT_KNOWLEDGE = exports.DELETE_KNOWLEDGE = exports.UPSERT_KNOWLEDGE_CATEGORY = exports.DELETE_KNOWLEDGE_CATEGORY = void 0;
var client_1 = require("@apollo/client");
var DELETE_KNOWLEDGE_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation deleteKnowledgeCategory($id: ID!, $organization_id: ID!) {\n\t\tdeleteKnowledgeCategory(id: $id, organization_id: $organization_id)\n\t}\n"], ["\n\tmutation deleteKnowledgeCategory($id: ID!, $organization_id: ID!) {\n\t\tdeleteKnowledgeCategory(id: $id, organization_id: $organization_id)\n\t}\n"])));
exports.DELETE_KNOWLEDGE_CATEGORY = DELETE_KNOWLEDGE_CATEGORY;
var UPSERT_KNOWLEDGE_CATEGORY = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tmutation upsertKnowledgeCategory($input: [KnowledgeCategoryPayload!]!) {\n\t\tupsertKnowledgeCategory(input: $input)\n\t}\n"], ["\n\tmutation upsertKnowledgeCategory($input: [KnowledgeCategoryPayload!]!) {\n\t\tupsertKnowledgeCategory(input: $input)\n\t}\n"])));
exports.UPSERT_KNOWLEDGE_CATEGORY = UPSERT_KNOWLEDGE_CATEGORY;
var DELETE_KNOWLEDGE = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tmutation deleteKnowledge(\n\t\t$knowledge_id: ID!\n\t\t$knowledge_category_id: ID\n\t\t$organization_id: ID!\n\t) {\n\t\tdeleteKnowledge(\n\t\t\torganization_id: $organization_id\n\t\t\tknowledge_id: $knowledge_id\n\t\t\tknowledge_category_id: $knowledge_category_id\n\t\t)\n\t}\n"], ["\n\tmutation deleteKnowledge(\n\t\t$knowledge_id: ID!\n\t\t$knowledge_category_id: ID\n\t\t$organization_id: ID!\n\t) {\n\t\tdeleteKnowledge(\n\t\t\torganization_id: $organization_id\n\t\t\tknowledge_id: $knowledge_id\n\t\t\tknowledge_category_id: $knowledge_category_id\n\t\t)\n\t}\n"])));
exports.DELETE_KNOWLEDGE = DELETE_KNOWLEDGE;
var UPSERT_KNOWLEDGE = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tmutation upsertKnowledge(\n\t\t$knowledge_id: ID\n\t\t$title: String!\n\t\t$description: String\n\t\t$sorting: Int\n\t\t$knowledge_category_id: ID!\n\t\t$knowledge_media: KnowledgeMediaInput!\n\t\t$organization_id: ID!\n\t) {\n\t\tupsertKnowledge(\n\t\t\tknowledge_id: $knowledge_id\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tsorting: $sorting\n\t\t\tknowledge_category_id: $knowledge_category_id\n\t\t\tknowledge_media: $knowledge_media\n\t\t\torganization_id: $organization_id\n\t\t)\n\t}\n"], ["\n\tmutation upsertKnowledge(\n\t\t$knowledge_id: ID\n\t\t$title: String!\n\t\t$description: String\n\t\t$sorting: Int\n\t\t$knowledge_category_id: ID!\n\t\t$knowledge_media: KnowledgeMediaInput!\n\t\t$organization_id: ID!\n\t) {\n\t\tupsertKnowledge(\n\t\t\tknowledge_id: $knowledge_id\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tsorting: $sorting\n\t\t\tknowledge_category_id: $knowledge_category_id\n\t\t\tknowledge_media: $knowledge_media\n\t\t\torganization_id: $organization_id\n\t\t)\n\t}\n"])));
exports.UPSERT_KNOWLEDGE = UPSERT_KNOWLEDGE;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
