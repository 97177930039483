"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperAdminRoutes = exports.AdminRoutes = exports.ClientRoutes = exports.NonAuthRoutes = exports.AuthRoutes = void 0;
var AuthRoutes;
(function (AuthRoutes) {
    // dashboard = "/dashboard",
    AuthRoutes["organization"] = "/organization";
    AuthRoutes["country"] = "/country";
})(AuthRoutes = exports.AuthRoutes || (exports.AuthRoutes = {}));
var NonAuthRoutes;
(function (NonAuthRoutes) {
    NonAuthRoutes["login"] = "/";
    NonAuthRoutes["unauthorized"] = "/unauthorized";
})(NonAuthRoutes = exports.NonAuthRoutes || (exports.NonAuthRoutes = {}));
var ClientRoutes;
(function (ClientRoutes) {
    ClientRoutes["dashboard"] = "/agent/dashboard";
    ClientRoutes["usage"] = "/agent/usage";
    ClientRoutes["education"] = "/agent/education";
    ClientRoutes["service"] = "/agent/service";
    ClientRoutes["underConstruction"] = "/agent/underConstruction";
})(ClientRoutes = exports.ClientRoutes || (exports.ClientRoutes = {}));
var AdminRoutes;
(function (AdminRoutes) {
    AdminRoutes["organization"] = "/admin/organization";
    AdminRoutes["dashboard"] = "/admin/dashboard";
    AdminRoutes["underConstruction"] = "/admin/underConstruction";
    AdminRoutes["sensitation"] = "/admin/guide";
    AdminRoutes["knowledge"] = "/admin/knowledge";
    AdminRoutes["tabularOption"] = "/admin/tabularOption";
    AdminRoutes["setting"] = "/admin/setting";
    AdminRoutes["report"] = "/admin/report";
    AdminRoutes["generalReport"] = "/admin/general";
})(AdminRoutes = exports.AdminRoutes || (exports.AdminRoutes = {}));
var SuperAdminRoutes;
(function (SuperAdminRoutes) {
    SuperAdminRoutes["dashboard"] = "/superadmin/dashboard";
})(SuperAdminRoutes = exports.SuperAdminRoutes || (exports.SuperAdminRoutes = {}));
