"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reportValidate = void 0;
var general = function (payload) {
    var fromDate = replaceData(payload.from);
    var toDate = replaceData(payload.to);
    if (!payload.from) {
        return {
            status: 'error',
            field: 'from',
            message: 'from_date_is_required',
        };
    }
    else if (!payload.to) {
        return {
            status: 'error',
            field: 'to',
            message: 'to_date_is_required',
        };
    }
    else if (fromDate > toDate) {
        return {
            status: 'error',
            field: 'from',
            message: 'end_date_must_be_greater_than_or_equals_to_start_date',
        };
    }
    else {
        return {
            status: 'success',
            message: 'success',
            field: null,
        };
    }
};
var replaceData = function (value) {
    var valueString = value.toString();
    var valueUpdated = new Date(valueString.replace(/-/g, '/'));
    return valueUpdated;
};
var areaMapping = function (payload) {
    var fromDate = payload.from ? replaceData(payload.from) : null;
    var toDate = payload.to ? replaceData(payload.to) : null;
    if (fromDate && toDate && fromDate > toDate) {
        return {
            status: 'error',
            field: 'from',
            message: 'end_date_must_be_greater_than_or_equals_to_start_date',
        };
    }
    else {
        return {
            status: 'success',
            message: 'success',
            field: null,
        };
    }
};
exports.reportValidate = { general: general, areaMapping: areaMapping };
