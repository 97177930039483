"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPSERT_SENSITATION = exports.DELETE_SENSITATION = void 0;
var client_1 = require("@apollo/client");
var DELETE_SENSITATION = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmutation deleteSensitation($organization_id: ID!, $sensitation_id: ID!) {\n\t\tdeleteSensitation(\n\t\t\tsensitation_id: $sensitation_id\n\t\t\torganization_id: $organization_id\n\t\t)\n\t}\n"], ["\n\tmutation deleteSensitation($organization_id: ID!, $sensitation_id: ID!) {\n\t\tdeleteSensitation(\n\t\t\tsensitation_id: $sensitation_id\n\t\t\torganization_id: $organization_id\n\t\t)\n\t}\n"])));
exports.DELETE_SENSITATION = DELETE_SENSITATION;
var UPSERT_SENSITATION = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tmutation upsertSensitation(\n\t\t$sensitation_id: ID\n\t\t$title: String!\n\t\t$description: String\n\t\t$sorting: Int\n\t\t$sensitation_media: SensitationMediaInput!\n\t\t$organization_id: ID!\n\t\t$published: Boolean\n\t) {\n\t\tupsertSensitation(\n\t\t\tsensitation_id: $sensitation_id\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tsorting: $sorting\n\t\t\tsensitation_media: $sensitation_media\n\t\t\torganization_id: $organization_id\n\t\t\tpublished: $published\n\t\t)\n\t}\n"], ["\n\tmutation upsertSensitation(\n\t\t$sensitation_id: ID\n\t\t$title: String!\n\t\t$description: String\n\t\t$sorting: Int\n\t\t$sensitation_media: SensitationMediaInput!\n\t\t$organization_id: ID!\n\t\t$published: Boolean\n\t) {\n\t\tupsertSensitation(\n\t\t\tsensitation_id: $sensitation_id\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tsorting: $sorting\n\t\t\tsensitation_media: $sensitation_media\n\t\t\torganization_id: $organization_id\n\t\t\tpublished: $published\n\t\t)\n\t}\n"])));
exports.UPSERT_SENSITATION = UPSERT_SENSITATION;
var templateObject_1, templateObject_2;
