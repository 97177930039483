"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateSensitationForm = void 0;
var ValidateSensitationForm = function (payload, currentLanguage) {
    if (JSON.parse(payload.title)[currentLanguage] === '') {
        return {
            message: 'required',
            field: 'title',
            status: 'error',
        };
    }
    return {
        message: 'success',
        field: '',
        status: 'success',
    };
};
exports.ValidateSensitationForm = ValidateSensitationForm;
