"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var react_router_dom_1 = require("react-router-dom");
var general_1 = require("../../lib/general");
function SubHeader(props) {
    var _a = (0, react_i18next_1.useTranslation)(), t = _a.t, i18n = _a.i18n, currentLang = i18n.language, _b = (0, react_1.useState)(''), cliked = _b[0], setClicked = _b[1];
    (0, react_1.useEffect)(function () {
        setClicked('');
    }, [props.list]);
    var renderMenus = function () { return (react_1.default.createElement("div", { style: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
            background: '#dc8006',
            borderRadius: 5,
        } },
        react_1.default.createElement("ul", { className: 'sub-menu' }, props.list.length > 0 &&
            props.list.map(function (item, key) {
                var name = (0, general_1.isJsonWithAvailableLang)(item.name, currentLang.toLowerCase(), t);
                return (react_1.default.createElement("div", { key: key, style: { marginLeft: '0.5rem' } },
                    react_1.default.createElement(react_router_dom_1.Link, { className: cliked === key ? 'active' : '', onClick: function () {
                            setClicked(key);
                            props.onSubChangeTab(item);
                        }, style: {
                            color: '#fff',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            fontFamily: 'Montserrat',
                            textDecoration: 'none',
                        }, to: "".concat(props.path, "/").concat(name) },
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("div", null, name)),
                        cliked === key && react_1.default.createElement("div", { className: 'horizontal-sub-line' }))));
            })))); };
    return react_1.default.createElement("div", { className: 'sticky-submenu' }, renderMenus());
}
exports.default = SubHeader;
