// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../assets/images/bg.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat fixed 0 0,#fffdfc;background-size:contain;display:flex;flex:1;flex-direction:row;height:100vh;width:100vw}.login .left{align-items:flex-start;display:flex;flex:1;justify-content:flex-start}.login .right{flex:1;justify-content:center}.login .right,.login .right .login-box{align-items:center;display:flex;flex-direction:column}.login .right .login-box{justify-content:flex-start;padding:1rem;width:50%}", "",{"version":3,"sources":["webpack://./resources/js/src/components/Login/Style/login.sass"],"names":[],"mappings":"AAAA,OAEI,8EAAA,CAIA,uBAAA,CALA,YAAA,CAQA,MAAA,CACA,kBAAA,CAHA,YAAA,CACA,WAGJ,CAEI,aAII,sBAAA,CAHA,YAAA,CACA,MAAA,CACA,0BACR,CAEI,cAII,MAAA,CACA,sBAAR,CAIQ,uCAHA,kBAAA,CALA,YAAA,CAMA,qBAMR,CAJQ,yBAGI,0BAAA,CADA,YAAA,CAGA,SADZ","sourcesContent":[".login\n    display: flex\n    background: url(\"../../../assets/images/bg.svg\") no-repeat fixed top left, #FFFDFC\n    -webkit-background-size: contain\n    -moz-background-size: contain\n    -o-background-size: contain\n    background-size: contain\n    height: 100vh\n    width: 100vw\n    flex: 1\n    flex-direction: row\n\n\n    .left\n        display: flex\n        flex: 1\n        justify-content: flex-start\n        align-items: flex-start\n\n    .right\n        display: flex\n\n        //border: 1px solid #000\n        flex: 1\n        justify-content: center\n        align-items: center\n        flex-direction: column\n\n        .login-box\n            display: flex\n            padding: 1rem\n            justify-content: flex-start\n            align-items: center\n            width: 50%\n            //border: 3px solid #ccc\n            //height: 50vh\n            flex-direction: column\n\n\n//overflow-x: hidden\n//position: absolute\n//top: 100%\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
