"use strict";
/* eslint-disable no-unused-vars */
Object.defineProperty(exports, "__esModule", { value: true });
exports.roles = exports.Roles = void 0;
var Roles;
(function (Roles) {
    Roles["admin"] = "Administrator";
    Roles["superAdmin"] = "Super admin";
    Roles["agent"] = "Agent";
    Roles["keyActivator"] = "Key Activator";
    Roles["masterActivator"] = "Master Activator";
    Roles["superActivator"] = "Super Activator";
    Roles["expert"] = "Expert";
})(Roles = exports.Roles || (exports.Roles = {}));
exports.roles = {
    admins: [String(Roles.admin), String(Roles.superAdmin)],
    // admins: [String(Roles.keyActivator), String(Roles.agent)],
    users: [
        String(Roles.expert),
        String(Roles.keyActivator),
        String(Roles.masterActivator),
        String(Roles.superActivator),
        String(Roles.agent),
    ],
    all: [
        String(Roles.admin),
        String(Roles.expert),
        String(Roles.keyActivator),
        String(Roles.masterActivator),
        String(Roles.superActivator),
        String(Roles.agent),
    ],
};
